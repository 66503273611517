import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO/index"
import NavBar from "../components/navbar"

import "./mystyles.scss"

const TraumatologyOrthopedicsPage = ({ location }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <NavBar />
      {/* <Section white>
        <div className="content"></div>
      </Section> */}
    </>
  )
}

export default injectIntl(TraumatologyOrthopedicsPage)
